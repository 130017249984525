import { reactive, toRefs } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import { useDebounce } from "@/hooks/core/useDebounce";
import API from "@/api/studentCard";
import { useMessage } from "@/hooks/web/useMessage";
import { sleep } from "@/utils/sleep";
export default {
  name: "ExportSimCardSelect",
  components: {
    Eldialog
  },
  props: {
    searchForm: {
      type: Object
    }
  },
  emits: ['handleSuccess'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      visible: false,
      type: 1,
      loading: false
    });
    //保存
    const confirm = async () => {
      refData.loading = true;
      const params = {
        childFlag: props.searchForm.holdid ? 1 : 0,
        holdid: props.searchForm.holdid || "",
        schoolId: props.searchForm.schoolId || "",
        mdtids: props.searchForm.mdtids,
        contactertel: props.searchForm.contactertel,
        mdtid3: props.searchForm.mdtid3,
        objectname: props.searchForm.objectname,
        deviceType: props.searchForm.deviceType ? props.searchForm.deviceType : "00",
        activationStatus: 0,
        activationBeginTime: props.searchForm.activationTime && props.searchForm.activationTime.length ? props.searchForm.activationTime[0] + " 00:00:00" : "",
        activationEndTime: props.searchForm.activationTime && props.searchForm.activationTime.length ? props.searchForm.activationTime[1] + " 23:59:59" : "",
        createBeginTime: props.searchForm.createTime && props.searchForm.createTime.length ? props.searchForm.createTime[0] + " 00:00:00" : "",
        createEndTime: props.searchForm.createTime && props.searchForm.createTime.length ? props.searchForm.createTime[1] + " 23:59:59" : "",
        bindingStatus: props.searchForm.bindingStatus,
        useStatus: props.searchForm.useStatus,
        onlineStatus: props.searchForm.onlineStatus
      };
      await API[refData.type === 1 ? 'StudentExportExcel' : 'exportStuSosWhiteList'](params);
      await sleep(2000);
      refData.visible = false;
      refData.loading = false;
    };
    const [debounceConfirm] = useDebounce(confirm, 300);
    return {
      ...toRefs(refData),
      debounceConfirm
    };
  }
};